import Vue from "vue";

Vue.filter('formatDate', (value)=>{
    if(!value) return '---';
    var day = value.substring(8,10);
    var month = value.substring(5,7);
    var year = value.substring(0,4);
    var dateBR = day+'/'+month+'/'+year;
    return dateBR;
})

Vue.filter('formatDateTime', (value)=>{
    if(!value) return '---';
    var day = value.substring(8,10);
    var month = value.substring(5,7);
    var year = value.substring(0,4);
    var hour = value.substring(11,13);
    var minute = value.substring(14,16);
    var dateBR = day+'/'+month+'/'+year+' '+hour+':'+minute;
    return dateBR;
})

Vue.filter('formatCnpj', function (cnpj) {
    if(cnpj == null || cnpj == "")
        return cnpj;
  
  let cleaned = cnpj.replace(/\D/g, '');
  let regex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
  let match = cleaned.match(regex);

  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
  } else {
    return cnpj;
  }
});

Vue.filter('validateCPF', (value)=>{
    // Remove non-numeric characters
    const cpf = value.replace(/\D/g, '');

    if (cpf.length !== 11) {
        return false;
    }

    // Check for repeated digits (e.g., 111.111.111-11)
    if (/^(\d)\1+$/.test(cpf)) {
        return false;
    }

    // Validate the CPF digits
    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf[i - 1]) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }

    if (remainder !== parseInt(cpf[9])) {
        return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf[i - 1]) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }

    if (remainder !== parseInt(cpf[10])) {
        return false;
    }

    return true;
})

Vue.filter('validateCNPJ', (value)=>{
    // Remove any non-numeric characters
    const cnpj = value.replace(/[^\d]/g, '');

    // CNPJ must be 14 characters
    if (cnpj.length !== 14) {
        return false;
    }

    // Check CNPJ validation algorithm
    let numbers = cnpj.substring(0, 12);
    const digits = cnpj.substring(12);

    let sum = 0;
    let position = 5;

    for (let i = 0; i < 12; i++) {
        sum += parseInt(numbers.charAt(i)) * position;
        position--;
        if (position < 2) {
            position = 9;
        }
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(0))) {
        return false;
    }

    numbers = cnpj.substring(0, 13);
    sum = 0;
    position = 6;

    for (let i = 0; i < 13; i++) {
        sum += parseInt(numbers.charAt(i)) * position;
        position--;
        if (position < 2) {
            position = 9;
        }
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(1))) {
        return false;
    }

    return true;
})

Vue.filter('dataURLtoFile', (dataurl, filename)=>{
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
})

Vue.filter('formatPhone', (phone)=>{
    if (!phone) return "---";

    let completePhone = phone;
    if (completePhone && completePhone.length === 10)
        return completePhone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    if (completePhone && completePhone.length === 11)
        return completePhone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    return phone;
})

Vue.filter('textColorBlackOrWhite', (hexCode)=>{
    if(!hexCode) return 'white';
    const hex = hexCode.slice(1);
    const [r, g, b] = [0, 2, 4].map(i => parseInt(hex.slice(i, i + 2), 16) / 255);
    const color = ((0.2126 * r + 0.7152 * g + 0.0722 * b) > 0.5) ? 'black' : 'white';
    return color;
})