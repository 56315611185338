<template>
    <b-row class="m-0 h-100">
        <b-col id="leftTable" ref="leftTable" v-dragscroll class="p-0" :style="'max-height: '+height+' !important; min-width:50%; max-width:'+width+'; overflow-x: auto; border-right: 2px solid #262625'">

            <table>
                <!-- HEADER -->
                <thead style="position: sticky; top:0px; z-index: 2 !important;">
                    <tr style="background-color:#535252 !important;" v-if="withOrder">
                        <!-- <th></th> -->
                        <th @click="sort('n_document')" class="text-left">
                            <span style="font-size:26px;" :style="sortBy=='n_document' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='n_document' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            CNPJ </th>
                        <th @click="sort('fund')" class="text-left">
                            <span style="font-size:26px;" :style="sortBy=='fund' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='fund' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Fundo </th>
                        <th @click="sort('absolute_return_12_months')" class="text-right">
                            <span style="font-size:26px;" :style="sortBy=='absolute_return_12_months' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='absolute_return_12_months' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Performance </th>
                        <th @click="sort('volatility')" class="text-right">
                            <span style="font-size:26px;" :style="sortBy=='volatility' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='volatility' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Vol. </th>
                        <th @click="sort('opportunities')" class="text-right">
                            <span style="font-size:26px;" :style="sortBy=='opportunities' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='opportunities' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Oportunidade </th>
                    </tr>
                    <tr style="background-color:#535252 !important;" v-else>
                        <!-- <th></th> -->
                        <th class="text-left">
                            CNPJ </th>
                        <th class="text-left">
                            Fundo </th>
                        <th class="text-right">
                            Performance </th>
                        <th class="text-right">
                            Vol. </th>
                        <th class="text-right">
                            Oportunidade </th>
                    </tr>
                </thead>

                <!-- CONTENT -->
                <tbody v-for="row,row_idx in items" :key="row_idx" class="w-100" style="background-color: white;">
                    <tr v-if="row.description" class="w-100" style="transition:.25s" :style="row.items.every(e=>e!=modal) && modal  ? 'opacity:0.7;' : ''">
                        <td class="p-2 px-3" colspan="5" :style="'width:100% !important;  background-color: #'+row.color_code+' !important; color: '+$options.filters.textColorBlackOrWhite('#'+row.color_code)+' !important;'">
                            {{ row.description }}  
                        </td>
                    </tr>
                    <tr v-if="row.items?.length==0"> <td colspan="5" style="padding:0px !important"> <div class="px-3 d-flex align-items-center" style="height:55px;"> Não há dados disponíveis </div> </td> </tr>
                    <tr :class="[
    modal === item ? 'row-select-possibility selected' : 'row-select-possibility',
    modal !== item && modal ? 'opacity-row' : ''
  ]" v-for="item,item_idx in row.items" :key="item_idx" :style="modal!=item && modal  ? 'opacity:0.7;' : 'color: red;'"
                    @click="()=>{
                        if(canSelect){
                            modal=item; 
                            $emit('select', {...row, items: [item]})
                        }
                    }">
                        <!-- <td style="height:55px !important; min-width:max-content;">
                            <b-row style="min-width:max-content;">
                                <b-button v-if="canDelete" @click="$emit('delete', {row_idx:row_idx, item_idx:item_idx})"
                                    style="z-index:1 !important; width: 35px; height: 30px; background-color: #302f2f; color:#EB694A; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252;"
                                >
                                    <b-icon icon="trash-fill"></b-icon>
                                </b-button>

                                <div class="px-2"></div>

                                <b-button :disabled="!item.fund_page_xp_platform" :style="item.fund_page_xp_platform ? '' : 'opacity:0.4'"
                                    style="z-index:1 !important; width: 35px; height: 30px; background-color: #302f2f; color:#EB694A; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252;"
                                    :href="item.fund_page_xp_platform" target="_blank"
                                >
                                    <b-icon icon="file-earmark-text"></b-icon>
                                </b-button>
                            </b-row>
                        </td> -->
                        <td> {{ item.target['n_document'] || '---' | formatCnpj }} </td>
                        <td> {{ item.target['fund'] || '---' }} </td>
                        <td class="text-right"> {{ item.target['absolute_return_12_months'] || '---' }} </td>
                        <td class="text-right"> {{ item.target['volatility'] || '---' }} </td>
                        <td class="text-right"> {{ item.target['opportunities'].length || '---' }} </td>
                    </tr>
                </tbody>
            </table>

        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'TopFundsOpportunitiesTable',
    props:{
        height: {
            type: String,
            default: '100%',
        },
        width: {
            type: String,
            default: 'max-content',
        },
        items: {
            type: Array,
            default: ()=>{ return []; },
            required: true
        },
        canSelect: {
            type: Boolean,
            default: false,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        withOrder: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            sortBy: null,
            sortDesc: true,
            modal: null,
        }
    },
    methods:{
        sort(key){
            if(this.sortBy==key)
                this.sortDesc = !this.sortDesc;
            else this.sortDesc = false;
            this.sortBy = key;
            this.items.forEach(row=>{
                row.items.sort((a,b)=>
                {
                    if(this.sortDesc)
                        return String(b.target[key]).localeCompare(String(a.target[key]), undefined, {numeric: Boolean(Number(a.target[key])) })
                    else return String(a.target[key]).localeCompare(String(b.target[key]), undefined, {numeric: Boolean(Number(a.target[key])) })
                })
            })
        },
    },
}
</script>

<style scoped>
.row-select-possibility{
    cursor: pointer;
    transition: 0.25s;
}
.row-select-possibility td:hover{
    background: white;
}

th, td, thead, tr, tbody, table{
    min-width: max-content !important;
}
tbody td {
    padding-bottom: 8px !important;
}

/* #leftTable::-webkit-scrollbar{
    display: none;
} */
th {
    cursor: pointer;
}
td{
    padding-left: 20px;
}

.row-select-possibility {
  transition: all 0.3s ease;
}

.row-select-possibility.selected {
  border: 2px solid red;
  box-shadow: 0 0 0 2px red;
  z-index: 1;
  position: relative;
}

.opacity-row {
  opacity: 0.7;
}
</style>