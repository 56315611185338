<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Detalhes do E-mail'"
            :haveBackButton="true"
            @onBack="$router.push({name: 'emailsSendersView', params: {
                item: $route.params.item,
                destinataryItems: $route.params.destinataryItems,
            }})"
            >

            <template #filters>
                <b-row class="mx-0">
                    <b-col class="px-1 pr-2" cols="4">
                        <div class="form-group">
                            <label for="inputOptionEmail">Classificação</label>
                            <input class="form-control form-control-sm" id="inputOptionEmail" style="box-shadow: none; height: 56px;"
                            v-model="item.classification.name" disabled>
                        </div>
                    </b-col>
                    <b-col class="pl-2 px-1" cols="8">
                        <div class="form-group">
                            <label for="inputSubject">Assunto</label>
                            <input class="form-control form-control-sm" id="inputSubject" style="box-shadow: none; height: 56px;"
                            v-model="item.subject" disabled>
                        </div>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-col style="min-height:100%;">
                <b-row class="mx-0 py-4">
                    <b-checkbox v-model="advisorIdentity" size="lg" disabled> Identidade do assessor </b-checkbox>
                </b-row>

                <div style="position: relative;">
                    
                    <div v-if="advisorIdentity" class="w-100 d-flex align-items-center justify-content-center"
                    style="position: absolute; top:65px;">
                        <img class="img-fluid" src="@/assets/header.png" alt="Imagem da identidade do assessor no cabeçalho"
                        style="max-height:100px;">
                    </div>
                    
                    <vue-editor v-model="message" disabled
                    :editor-toolbar="customToolbar"
                    :editorOptions="editorSettings"
                    :customModules="customModulesForEditor"
                    :theme="'Bubble'"
                    :class="advisorIdentity ? 'advisor-identity' : ''"
                    style="background-color:white; color: black;
                    height:100% !important"/>
                    
                    <div v-if="advisorIdentity" class="w-100 d-flex align-items-center justify-content-center"
                    style="position: absolute; bottom:15px;">
                        <img class="img-fluid" src="@/assets/footer.png" alt="Imagem da identidade do assessor no rodapé"
                        style="max-height:100px;">
                    </div>

                </div>

                <div class="w-100" style="height:130px; position: absolute; z-index:2 !important; top:0;"></div>
            </b-col>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>
        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import { VueEditor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";

export default {
    name: "EmailsNewView",
    components:{
        DefaultPageComponent,
        VueEditor,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,

            templateSelected: null,

            classification: null,
            subject: null,
            message: null,
            advisorIdentity: false,

            classifications: [],
            email_templates: [],

            item: {},
            customToolbar: [
                [{'font':[]}, {'header':[]}],
                [
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                ],
                [
                    {'align': []},
                ],
                [
                    {'indent': '-1'},
                    {'indent': '+1'},
                    {'list': 'ordered'},
                    {'list': 'bullet'},
                ],
                [
                    {'color': []},
                    {'background': []},
                ],
                [
                    'blockquote',
                    'code-block',
                    'code',
                ],
                [
                    'link',
                    'image',
                ],
            ],
            customModulesForEditor: [
                { alias: "imageDrop", module: ImageDrop },
                { alias: "imageResize", module: ImageResize }
            ],
            editorSettings: {
                modules: {
                    imageDrop: true,
                    imageResize: {
                        displaySize: true
                    },
                },
            }
        }
    },
    created(){
        this.getClassifications();
        this.getTemplates();

        this.item = this.$route.params.item

        this.classification = this.item.classification
        this.subject = this.item.subject
        this.message = this.item.body
        this.advisorIdentity = this.item.include_advisor_identity
    },
    computed:{
        isValid(){
            return Boolean(
                this.classification
                && this.subject
                && this.message
            );
        },
    },
    methods: {
        async getItem(){
            try{
                this.loading=true;
                var endpoint = 'email-sent/'+this.$route.params.id;
    
                const response = await this.$axios.get(endpoint);
                this.item = response.data;

                this.message = this.item.body;
                this.subject = this.item.subject;
                this.advisorIdentity = this.item.include_advisor_identity==1;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getClassifications(){
            try{
                this.loading=true;
                var endpoint = 'email-classification';
                const response = await this.$axios.get(endpoint);
                this.classifications = response.data.data;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getTemplates(){
            try{
                this.loading=true;
                var endpoint = 'email-template';
                endpoint+= '?status=1'
                const response = await this.$axios.get(endpoint);
                this.email_templates = response.data;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        verifyClassification(){
            if(this.classification==1) return;
            else if((this.email_templates.filter(e=>e.template.email_classification_id==this.classification).length==0)){
                this.$swal({
                    title: 'Atenção',
                    text: 'Não há e-mail padrão criado com essa classificação.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                })
                .then(()=>{
                    this.classification=null;
                })
            }
        },
        selectSubject(){
            this.templateSelected = this.email_templates.find(e=>e.template.email_classification_id==this.classification && e.template.subject==this.subject);
            this.message = this.templateSelected.template.body.replace('\\', '');
            this.advisorIdentity = this.templateSelected.template.include_advisor_identity==1 ? true : false;
        },
        alertSubject(){
            if((!this.classification && !this.item.classification_name)){
                this.$swal({
                    title: 'Atenção',
                    text: 'Escolha uma classificação para habilitar o campo de assunto.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        alertBody(){
            if((!this.classification && !this.item.classification_name) && !this.subject){
                this.$swal({
                    title: 'Atenção',
                    text: 'Escolha uma classificação e preencha o assunto antes de habilitar este campo.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
            else if(!this.subject){
                this.$swal({
                    title: 'Atenção',
                    text: 'Preencha o assunto antes de habilitar este campo.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        validate(){
            if(!this.isValid){
                this.$swal({
                    title: 'Atenção',
                    text: 'Preencha o formulário antes de continuar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return false
            }
            else return true;
        },
        next(){
            if(!this.validate()) return;

            var temp = {
                classification: this.classification,
                subject: this.subject,
                body: this.message,
                include_advisor_identity: this.advisorIdentity,
            }

            this.$router.push({name: 'emailsSendersView', params: {item: temp}})
        },
    },
}
</script>

<style scoped>
* /deep/ .advisor-identity .ql-editor {
    padding: 120px 15px;
    min-height:300px;
}

* /deep/ .ql-font-serif{
    font-family: Georgia, Times New Roman, serif !important;
}
* /deep/ .ql-font-monospace{
    font-family: Monaco, Courier New, monospace !important;
}
</style>