<template>
    <b-row class="m-0 h-100">
        <b-col id="leftTable" ref="leftTable" v-dragscroll @scroll="syncScroll('leftTable')" class="p-0" :style="'max-height: '+height+' !important; min-width:max-content !important; max-width:max-content; overflow-x: auto; border-right: 2px solid #262625'">

            <table>
                <!-- HEADER -->
                <thead style="position: sticky; top:0px; z-index: 2 !important;" >
                    <tr style="background-color:#535252 !important;">
                        <th></th>
                        <th @click="sort('n_document')" class="text-left">
                            <span style="font-size:26px;" :style="sortBy=='n_document' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='n_document' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            CNPJ </th>
                        <th @click="sort('fund')" class="text-left">
                            <span style="font-size:26px;" :style="sortBy=='fund' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='fund' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Fundo de investimento </th>
                    </tr>
                </thead>

                <!-- CONTENT -->
                <tbody v-for="row,row_idx in items" :key="row_idx" class="w-100">
                    <tr v-if="row.description" class="w-100">
                        <td class="p-2 px-3" colspan="3" :style="'width:100% !important;  background-color: #'+row.color_code+' !important; color: '+$options.filters.textColorBlackOrWhite('#'+row.color_code)+' !important;'">
                            {{ row.description }}  
                        </td>
                    </tr>
                    <tr v-if="row.funds.length==0"> <td colspan="3" style="padding:0px !important"> <div class="px-3 d-flex align-items-center" style="height:55px;"> Não há dados disponíveis </div> </td> </tr>
                    <tr v-for="item,item_idx in row.funds" :key="item_idx"  style="height:55px !important">
                        <td style="height:55px !important; min-width:max-content;">
                            <b-row style="min-width:max-content;">
                                <b-button v-if="canDelete" @click="$emit('delete', {row_idx:row_idx, item_idx:item_idx})"
                                    style="z-index:1 !important; width: 35px; height: 30px; background-color: #302f2f; color:#EB694A; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252;"
                                >
                                    <b-icon icon="trash-fill"></b-icon>
                                </b-button>

                                <div class="px-2"></div>

                                <b-button :disabled="!item.fund_page_xp_platform" :style="item.fund_page_xp_platform ? '' : 'opacity:0.4'"
                                    style="z-index:1 !important; width: 35px; height: 30px; background-color: #302f2f; color:#EB694A; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252;"
                                    :href="item.fund_page_xp_platform" target="_blank"
                                >
                                    <b-icon icon="file-earmark-text"></b-icon>
                                </b-button>
                            </b-row>
                        </td>
                        <td> {{ item['n_document'] || '---' | formatCnpj }} </td>
                        <td> {{ item['fund'] || '---' }} </td>
                    </tr>
                </tbody>
            </table>

        </b-col>
        <b-col ref="rightTable" v-dragscroll @scroll="syncScroll('rightTable')" class="p-0" :style="'max-height: '+height+' !important; max-width:100%; overflow-x: auto; border-left: 2px solid #262625'">

            <table>
                <thead style="position: sticky; top:0px;">
                    <tr>
                        <th @click="sort('xp_risk')" class="text-right"> 
                            <span style="font-size:26px;" :style="sortBy=='xp_risk' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='xp_risk' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Risco XP </th>
                        <th @click="sort('status')" class="text-left"> 
                            <span style="font-size:26px;" :style="sortBy=='status' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='status' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Status </th>
                        <th @click="sort('insurer')" class="text-left"> 
                            <span style="font-size:26px;" :style="sortBy=='insurer' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='insurer' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Seguradora </th>
                        <th @click="sort('xp_rating')" class="text-left"> 
                            <span style="font-size:26px;" :style="sortBy=='xp_rating' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='xp_rating' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Estratégia Classificação XP </th>
                        <th @click="sort('total_liquidity')" class="text-right"> 
                            <span style="font-size:26px;" :style="sortBy=='total_liquidity' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='total_liquidity' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Resgate (D+) </th>
                        <th @click="sort('initial_investment')" class="text-right"> 
                            <span style="font-size:26px;" :style="sortBy=='initial_investment' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='initial_investment' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Valor Inicial (R$) </th>
                        <th @click="sort('monthly_minimum')" class="text-right"> 
                            <span style="font-size:26px;" :style="sortBy=='monthly_minimum' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='monthly_minimum' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Mínimo Mensal (R$) </th>
                        <th @click="sort('participants')" class="text-left"> 
                            <span style="font-size:26px;" :style="sortBy=='participants' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='participants' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Público </th>
                        <th @click="sort('management_fee')" class="text-right"> 
                            <span style="font-size:26px;" :style="sortBy=='management_fee' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='management_fee' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Taxa de Adm (%) </th>
                        <th @click="sort('volatility')" class="text-right"> 
                            <span style="font-size:26px;" :style="sortBy=='volatility' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='volatility' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Volatilidade (%) </th>
                        <th @click="sort('sharpe_12m')" class="text-right"> 
                            <span style="font-size:26px;" :style="sortBy=='sharpe_12m' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='sharpe_12m' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Índice Sharp (%) </th>
                        <th @click="sort('roa')" class="text-right"> 
                            <span style="font-size:26px;" :style="sortBy=='roa' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='roa' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            ROA (%) </th>
                        <th @click="sort('performance_fee')" class="text-right"> 
                            <span style="font-size:26px;" :style="sortBy=='performance_fee' ? 'color:white;' :'color:gray;'">
                                <b-icon :icon="sortDesc&&sortBy=='performance_fee' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                            </span>
                            Perf (%) </th>
                        <th colspan="5" style="background-color:#262625 !important; padding:0px !important;">
                            <tr class="d-flex justify-content-center" style="background-color:#262625 !important; cursor:default;"> Perfomance do Fundo de Investimento (%) </tr>
                            <tr>
                                <th @click="sort('absolute_return_month')" class="text-right" style="background-color:#262625 !important; min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='absolute_return_month' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='absolute_return_month' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    Mês (%) </th>
                                <th @click="sort('absolute_return_year')" class="text-right" style="background-color:#262625 !important; min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='absolute_return_year' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='absolute_return_year' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    Ano (%) </th>
                                <th @click="sort('absolute_return_12m')" class="text-right" style="background-color:#262625 !important; min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='absolute_return_12m' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='absolute_return_12m' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    12 m (%) </th>
                                <th @click="sort('absolute_return_24m')" class="text-right" style="background-color:#262625 !important; min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='absolute_return_24m' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='absolute_return_24m' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    24 m (%) </th>
                                <th @click="sort('absolute_return_36m')" class="text-right" style="background-color:#262625 !important; min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='absolute_return_36m' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='absolute_return_36m' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    36 m (%) </th>
                            </tr>
                        </th>
                        <th colspan="5" style="padding:0px !important;">
                            <tr class="d-flex justify-content-center" style="background-color:#535252 !important; cursor:default;"> Perfomance do Benchmark CDI (%) </tr>
                            <tr>
                                <th @click="sort('cdi_return_percentage_month2')" class="text-right" style="min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='cdi_return_percentage_month2' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='cdi_return_percentage_month2' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    Mês (%) </th>
                                <th @click="sort('cdi_return_percentage_year2')" class="text-right" style="min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='cdi_return_percentage_year2' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='cdi_return_percentage_year2' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    Ano (%) </th>
                                <th @click="sort('cdi_return_percentage_12m2')" class="text-right" style="min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='cdi_return_percentage_12m2' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='cdi_return_percentage_12m2' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    12 m (%) </th>
                                <th @click="sort('cdi_return_percentage_24m2')" class="text-right" style="min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='cdi_return_percentage_24m2' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='cdi_return_percentage_24m2' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    24 m (%) </th>
                                <th @click="sort('cdi_return_percentage_36m2')" class="text-right" style="min-width:130px !important;"> 
                                    <span style="font-size:24px;" :style="sortBy=='cdi_return_percentage_36m2' ? 'color:white;' :'color:gray;'">
                                        <b-icon :icon="sortDesc&&sortBy=='cdi_return_percentage_36m2' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                                    </span>
                                    36 m (%) </th>
                            </tr>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="row,row_idx in items" :key="row_idx" class="w-100">
                    <tr v-if="row.description" class="w-100">
                        <td class="p-2 px-3" colspan="23" :style="'width:100% !important;  background-color: #'+row.color_code+' !important; color: '+$options.filters.textColorBlackOrWhite('#'+row.color_code)+' !important;'">
                            <div style="height:24px;"></div>
                        </td>
                    </tr>
                    <tr v-if="row.funds.length==0"> <td colspan="22" style="padding:0px !important"> <div class="px-3 d-flex align-items-center" style="height:55px;">  </div> </td> </tr>
                    <tr v-for="item,item_idx in row.funds" :key="item_idx">
                        
                        <td class="text-right" style="height:55px !important"> {{ item['xp_risk'] || '---' }} </td>
                        <td class="text-left"> {{ item['status'] || '---' }} </td>
                        <td class="text-left"> {{ item['insurer'] || '---' }} </td>
                        <td class="text-left"> {{ item['xp_rating'] || '---' }} </td>
                        <td class="text-right"> {{ item['total_liquidity'] || '---' }} </td>
                        <td class="text-right"> {{ Number(item['initial_investment']).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '---' }} </td>
                        <td class="text-right"> {{ Number(item['monthly_minimum']).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '---' }} </td>
                        <td class="text-left"> {{ item['participants'] || '---' }} </td>
                        <td class="text-right"> {{ item['management_fee'] || '---' }} </td>
                        <td class="text-right"> {{ item['volatility'] || '---' }} </td>
                        <td class="text-right"> {{ item['sharpe_12m'] || '---' }} </td>
                        <td class="text-right"> {{ item['roa'] || '---' }} </td>
                        <td class="text-right"> {{ item['performance_fee'] || '---' }} </td>

                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['absolute_return_month'] || '---' }} </td>
                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['absolute_return_year'] || '---' }} </td>
                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['absolute_return_12m'] || '---' }} </td>
                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['absolute_return_24m'] || '---' }} </td>
                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['absolute_return_36m'] || '---' }} </td>

                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['cdi_return_percentage_month2'] || '---' }} </td>
                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['cdi_return_percentage_year2'] || '---' }} </td>
                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['cdi_return_percentage_12m2'] || '---' }} </td>
                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['cdi_return_percentage_24m2'] || '---' }} </td>
                        <td class="text-right" style="min-width:130px !important; padding:2px 20px !important;"> {{ item['cdi_return_percentage_36m2'] || '---' }} </td>

                    </tr>
                </tbody>
            </table>

        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'TopPensionFundsTable',
    props:{
        items: {
            type: Array,
            default: ()=>{ return []; },
            required: true
        },
        height: {
            type: String,
            default: '100%',
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return{
            sortBy: null,
            sortDesc: true,
        }
    },
    methods:{
        syncScroll(scrolledComponent) {
            const leftTable = this.$refs.leftTable;
            const rightTable = this.$refs.rightTable;
            
            if (scrolledComponent == 'leftTable') {
                rightTable.scrollTop = leftTable.scrollTop;
            } else if (scrolledComponent == 'rightTable') {
                leftTable.scrollTop = rightTable.scrollTop;
            }
        },
        sort(key){
            if(this.sortBy==key)
                this.sortDesc = !this.sortDesc;
            else this.sortDesc = false;
            this.sortBy = key;
            this.items.forEach(row=>{
                row.funds.sort((a,b)=>
                {
                    if(this.sortDesc)
                        return String(b[key]).localeCompare(String(a[key]), undefined, {numeric: Boolean(Number(a[key])) })
                    else return String(a[key]).localeCompare(String(b[key]), undefined, {numeric: Boolean(Number(a[key])) })
                })
            })
        },
    },
}
</script>

<style scoped>
th, td, thead, tr, tbody, table{
    min-width: max-content !important;
}
thead {
    height:70px;
}
tbody td {
    padding-bottom: 8px !important;
}
td{
    border: none;
    border-collapse: collapse !important;
}

#leftTable::-webkit-scrollbar{
    display: none;
}

th{
    cursor: pointer;
}
</style>