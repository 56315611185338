<template>
    <div id="dashboard" class="d-flex flex-column justify-content-center align-items-center">
        <!-- <DualGraphicsComponent/> -->
        <img src="@/assets/logo_full.svg" alt="" style="width: 600px; height: 40%; object-fit:contain;">
        <LoginCardTable v-if="user.role==1"/>
    </div>
</template>

<script>
import LoginCardTable from '@/components/LoginCardTable.vue'
// import DualGraphicsComponent from '@/components/DualGraphicsComponent.vue'
import { mapState } from 'vuex'
export default {
    name: 'DashboardView',
    components:{
        LoginCardTable,
        // DualGraphicsComponent,
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }

    .chart-container {
        width: calc(100% - 10px) !important;
        min-height: 350px !important;
    }

    table thead tr th {
        background: #535252 !important;
        color: #F3F0EF !important;
        padding: 2px 20px !important;
        font-size: 20px;
        font-weight: 600;
        text-align: right;
    }

    table thead tr th:first-child {
        text-align: left;
    }

    table thead tr th:last-child {
    }

    table tbody tr td {
        background-color: #242323 !important;
        color: #F3F0EF !important;
        padding: 0px 20px !important;
        padding-top: 15px !important;
        font-weight: 600 !important;
    }

    #tableDetalhes tbody tr td {
        background-color: #242323f0 !important;
    }

    table tbody tr {
        border-bottom: 1px solid #535252 !important;
    }

    table tbody tr:last-child {
        border-bottom: 0px solid #535252 !important;
    }

    table tbody tr th {
        background-color: #363636f0 !important;
        color: #F3F0EF !important;
        padding-top: 15px !important;
        font-weight: 600 !important;
    }
</style>
