<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100%; width: 100%">
    <div style="height: 100%; width: 100%">
        <DefaultPageComponent
        :loading="loading"
        :title="'Configuração de e-mail'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        >

        <!-- CONTENT -->
        <div class="py-3 h-100" style="background-color:#262625;">
            <div class="mx-0 py-2 row d-flex justify-content-left align-items-center">
                <div class="col">
                    <div class="form-group">
                        <label for="inputSmtpHost">Host</label>
                        <input type="text" class="form-control form-control-sm" id="inputSmtpHost" style="box-shadow: none; min-height:56px;" v-model="modal.smtp_host">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="inputSmtpPort">Porta</label>
                        <input type="text" class="form-control form-control-sm" id="inputSmtpPort" style="box-shadow: none; min-height:56px;" v-model="modal.smtp_port">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="inputSmtpEncrypt">Criptografia</label>
                        <select class="form-control form-control-sm" id="inputSmtpEncrypt" style="box-shadow: none; min-height:56px;" v-model="modal.smtp_encryption">
                            <option value="TLS">TSL</option>
                            <option value="SSL">SSL</option>
                        </select>
                    </div>
                </div>
            </div>
            <b-row v-if="!this.modal.smtp_host || !this.modal.smtp_port || !this.modal.smtp_encryption" class="mx-0 mb-1" style="color: #D01F1F">
                <b-col style="transform:translateY(-5px);">
                    <span v-if="!this.modal.smtp_host"> Campo obrigatório </span> 
                </b-col>
                <b-col style="transform:translateY(-5px);">
                    <span v-if="!this.modal.smtp_port"> Campo obrigatório </span>
                </b-col>
                <b-col style="transform:translateY(-5px);">
                    <span v-if="!this.modal.smtp_encryption"> Campo obrigatório </span>
                </b-col>
            </b-row>
            <div class="mx-0 mt-3 py-2 row d-flex justify-content-left align-items-center">
                <div class="col-4">
                    <div class="form-group">
                        <label for="inputSmtpEmail">E-mail</label>
                        <input type="email" class="form-control form-control-sm" id="inputSmtpEmail" style="box-shadow: none; min-height:56px;" v-model="modal.smtp_user">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="inputSmtpPassword">Senha</label>
                        <input @input="verifyPass" :type="type" class="form-control form-control-sm" id="inputSmtpPassword" style="box-shadow: none; min-height:56px;" 
                        v-model="modal.smtp_password">
                        <b-button v-if="!modal.id || inputed" variant="link" style="color:#eb694a !important; box-shadow:none !important;
                          position:absolute; top:10px; right:20px;"
                          @mousedown="type='text'" @mouseup="type='password'" @mouseleave="type='password'">
                            <b-icon icon="eye-fill"></b-icon>
                        </b-button>
                    </div>
                </div>
            </div>
            <b-row v-if="!this.modal.smtp_user || !this.modal.smtp_password" class="mx-0 mb-1" style="color: #D01F1F">
                <b-col style="transform:translateY(-5px);">
                    <span v-if="!this.modal.smtp_user"> Campo obrigatório </span> 
                </b-col>
                <b-col style="transform:translateY(-5px);">
                    <span v-if="!this.modal.smtp_password"> Campo obrigatório </span> 
                </b-col>
                <b-col></b-col>
            </b-row>
        </div>

        <template #footer>
            <div class="row d-flex justify-content-end align-items-center m-0">
                    <CancelButton
                    @click="$router.push({ name: 'dashboard' })"
                    > Cancelar
                    </CancelButton>

                    <CustomButton
                    :disabled="!isValid"
                    :loading="loading || loadingSubmit"
                    @click="save"
                    > Salvar
                    </CustomButton>
            </div>
        </template>

        </DefaultPageComponent>

    </div>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CancelButton from '@/components/CancelButton.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: 'EmailConfigurationView',
    components:{
        DefaultPageComponent,
        CancelButton,
        CustomButton,
    },
    created() {
        this.saveLog();
        this.load();
    },
    data() {
        return {
            loading: false,
            loadingSubmit: false,
            inputed: false,
            type: 'password',
            templatePassword: '.-@!$%&()=,<>/?',
            modal: {
                smtp_host: null,
                smtp_port: null,
                smtp_user: null,
                smtp_password: null,
                smtp_encryption: null,
            },
        }
    },
    computed: {
        isValid(){
            return (
                this.modal.smtp_host
                && this.modal.smtp_port
                && this.modal.smtp_user
                && this.modal.smtp_password
                && this.modal.smtp_encryption
            )
        },
    },
    methods: {
        verifyPass(){
            if(!this.inputed){
                this.inputed=true;
                this.modal.smtp_password='';
            }
        },
        async load(){
            try{
                this.loading=true;
                
                var endpoint = 'email-configuration';
                endpoint += '?sector=marketing';

                const response = await this.$axios.get(endpoint);
                response.data.data.sort((a,b)=>b.id-a.id)
                if(response.data.data.length>0){
                    this.modal = {...this.modal, ...response.data.data[0]};
                    this.modal.smtp_password = this.templatePassword
                }
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os dados de configuração de e-mail.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            }
            finally{
                this.loading=false;
            }
        },
        async save(){
            if(!this.isValid){
                this.$swal({
                    title: 'Atenção',
                    text: 'Preencha os campos obrigatórios antes de salvar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            }

            try{
                this.loading=true;
                this.loadingSubmit=true;
                
                var endpoint = 'email-configuration';
                var param = '?sector=marketing';

                var temp = this.modal;
                if(temp.smtp_password == this.templatePassword)
                    delete temp.smtp_password;

                if(this.modal.id)
                    await this.$axios.put(endpoint+'/'+this.modal.id+param, temp);
                else
                    await this.$axios.post(endpoint+param, temp);

                this.load();

                this.$swal({
                    title: 'Sucesso',
                    text: 'Dados de configuração de e-mail atualizados com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28a745',
                })
                .then(()=>{
                    this.$router.go();
                })
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os dados de configuração de e-mail.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            }
            finally{
                this.loadingSubmit=false;
                this.loading=false;
            }
        },
        saveLog () {
            let objLog = {
                description: 'Configuração de E-mails'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
}
</script>
