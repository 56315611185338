<template>
    <div>
        <div style="background-color:#2B2B2B;">
            <b-row align-v="center" class="m-0 px-2" style="background-color:#CA4C34;">
                <b-col class="" style="font-size:18px; font-weight:600"> {{ period_options.find(e=>e.value==period).value==1 ? 'Acessos ao Sistema no último ' : 'Acessos ao Sistema nos últimos ' }} {{ period_options.find(e=>e.value==period).text != "1 mês" ? period_options.find(e=>e.value==period).text : 'mês' }} - {{ type }}</b-col>
                <b-col style="max-width:max-content">
                    <b-button @click="showFilter" variant="link" style="color:white;">
                        <b-icon icon="filter"></b-icon>
                    </b-button>
                </b-col>
            </b-row>
            <div class="p-3 px-4">
                <b-table :items="items" :fields="columns" sort-icon-left responsive sticky-header style="max-height:300px !important;">
                </b-table>
            </div>
        </div>
        <b-modal id="filterModal" title="Filtros" centered hide-footer hide-header-close header-class="bg-orange">
            <b-col class="p-3 px-4" style="color:white !important">
                <b-row class="m-0" style="font-weight:600;">Período</b-row>
                <div class="py-1"></div>
                <b-row class="m-0">
                    <b-form-radio-group
                    id="radio-group-1"
                    v-model="tempPeriod"
                    :options="period_options"
                    name="radio-period"
                    ></b-form-radio-group>
                </b-row>

                <div class="py-2 my-1"></div>

                <b-row class="m-0" style="font-weight:600;">Ordenar por</b-row>
                <div class="py-1"></div>
                <b-row class="m-0">
                    <b-form-radio-group
                    id="radio-group-2"
                    v-model="tempSort"
                    :options="sort_options"
                    name="radio-sort"
                    ></b-form-radio-group>
                </b-row>

                <div class="py-2 my-1"></div>

                <b-row class="m-0">
                    <div class="col p-0">
                        <div class="form-group">
                            <label for="" style="background-color: #262625 !important; border-radius:6px; font-size: 16px; font-weight:600;">Filtrar por</label>
                            <select class="form-control form-control-sm" id="inputCelula" style="box-shadow: none; height: 56px; font-size: 16px; background-color:transparent;"
                            v-model="tempType">
                                <option v-for="item in type_options" :key="item.value" :value="item.value">{{ item.text }} </option>
                            </select>
                        </div>
                    </div>
                </b-row>

                <div class="py-2 my-1"></div>

                <b-row class="m-0" align-h="end">
                    <CancelButton
                    @click="hideFilter"
                    > Cancelar
                    </CancelButton>

                    <div class="px-2"></div>

                    <CustomButton
                    @click="applyFilters"
                    > Aplicar
                    </CustomButton>
                </b-row>
            </b-col>
        </b-modal>
    </div>
</template>

<script>
import CancelButton from './CancelButton.vue'
import CustomButton from './CustomButton.vue'

export default {
    name: "LoginCardTable",
    components:{
        CancelButton,
        CustomButton,
    },
    data(){
        return {
            items: [],

            period: 6,
            type: 'Estratificação',
            sort: 'DESC',
            
            tempPeriod: null,
            tempSort: null,
            tempType: null,

            period_options: [
                { text: '6 meses', value: 6 },
                { text: '3 meses', value: 3 },
                { text: '1 mês', value: 1 },
            ],
            sort_options: [
                { text: 'Mais acessos', value: 'DESC' },
                { text: 'Menos acessos', value: 'ASC' },
            ],
            type_options: [
                { text: 'Login', value: 'Login' },
                { text: 'Estratificação', value: 'Estratificação' },
                { text: 'FGC', value: 'FGC' },
                { text: 'Relatório de Vencimentos Renda Fixa', value: 'Relatório de Vencimentos Renda Fixa' },
                { text: 'Top Funds', value: 'Top Funds' },
                { text: 'Top Funds Previdência', value: 'Top Funds Previdência' },
            ],
            columns: [
                {
                    'user_name': 
                    {
                        label: 'Usuário',
                        class: 'text-left',
                        // sortable: true,
                    }
                },
                {
                    'total_entries': 
                    {
                        label: 'Quantidade acessos',
                        class: 'text-center',
                        // sortable: true,
                    }
                },
                {
                    'last_activity_time': 
                    {
                        label: 'Último acesso',
                        class: 'text-right',
                        // sortable: true,
                        formatter: (value)=>{
                            return this.getFormattedDate(value)
                        }
                    }
                },
            ],
        }
    },
    created(){
        this.loadItems();
    },
    methods:{
        async loadItems(){
            const response = await this.$axios.get('logs/access-count-details?period='+this.period+'&type='+this.type+'&sort='+this.sort)
            this.items = response.data;
        },
        showFilter(){
            this.tempPeriod = this.period;
            this.tempSort = this.sort;
            this.tempType = this.type;
            this.$bvModal.show('filterModal')
        },
        hideFilter(){
            this.$bvModal.hide('filterModal')
        },
        applyFilters(){
            this.period = this.tempPeriod;
            this.sort = this.tempSort;
            this.type = this.tempType;
            this.loadItems();
            this.hideFilter();
        },
        getFormattedDate(date){
            if(!date) return '---';
            var day = date.substring(8,10);
            var month = date.substring(5,7);
            var year = date.substring(0,4);
            var hour = date.substring(11,13);
            var minute = date.substring(14,16);
            var dateBR = day+'/'+month+'/'+year+' '+hour+':'+minute;
            return dateBR;
        }
    },
}
</script>

<style scoped>
* /deep/ th{
    transform:translateY(-1px);
}
* /deep/ td{
    border:none !important;
    background-color: #2F2E2E !important;
    
}
* /deep/ table tbody tr td{
    padding: 5px 10px !important;
}
* /deep/ .close{
    color: white !important;
}
* /deep/ .modal-body{
    background-color: #2B2B2B !important;
}
* /deep/ .bg-orange{
    background-color: #CA4C34 !important;
    padding:10px 20px;
    font-weight:600;
    font-size:18px;
}
</style>