<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Top funds previdência - Data referência dos fundos - '+ $options.filters.formatDate(modal.reference_date)"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveNextButton="user.role==1"
            :buttonText="'Editar'"
            @onTap="$router.push({name: 'topPensionFundsForm'})"
            :haveContentMargin="true"
            >

            <template #header>
                <CustomButton @click="$router.push({name: 'topPensionFundsOpportunities'})"
                :width="'max-content !important'"
                > Oportunidade de melhoria
                </CustomButton>
                <div v-if="user.role==1" class="mx-2"></div>
            </template>

            <!-- CONTENT -->
            <div style="height: 100%">
                <div style="font-size:18px" class="pb-2">
                    {{ 'Data atualização - ' + $options.filters.formatDate(modal.updated_at) }}
                </div>

                <b-col class="py-3" style="height:calc(100% - 40px); background-color:#262625; border-radius:12px;">
                    <TopPensionFundsTable :items="items"/>
                </b-col>
            </div>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>
        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import TopPensionFundsTable from '@/components/TopPensionFundsTable.vue';
import { mapState } from 'vuex';
import CustomButton from '@/components/CustomButton.vue';

export default {
    name: "TopPensionFundsView",
    components:{
        DefaultPageComponent,
        TopPensionFundsTable,
        CustomButton,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,

            query: null,

            modal: {},
            items: [],
        }
    },
    created(){
        this.saveLog();
        this.getItems();
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
    methods: {
        async getItems(){
            try{
                this.loading=true;
                var endpoint = 'top-pension-funds';
                const response = await this.$axios.get(endpoint);
                this.modal = response.data;
                this.items = response.data.classifications;
                this.items.sort((a,b)=>a.position-b.position)
            }
            catch(error){
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar listagem de Top Funds Previdência.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        showSenders(item){
            item;
        },
        showDetails(item){
            item;
        },
        saveLog () {
            let objLog = {
                description: 'Top Funds Previdência'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    },
}
</script>

<style scoped>

</style>