<template>
    <div style="height: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Assessores'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Novo assessor'"
        @onTap="$router.push({name: 'advisorNew'})"
        :haveContentMargin="true"
        >

        <template #filters>
            <div class="row d-flex justify-content-center align-items-center mt-1">
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="inputPesquisarPor">Pesquisar por</label>
                        <select @change="filters.filter_query='';"
                        class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; min-height:56px;" v-model="filters.filter_by">
                            <option value="name">Nome</option>
                            <option value="wflow_code">Código WFLOW</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group" :style="!filters.filter_by ? 'opacity:.5;' : ''">
                        <label for="inputSearch">Pesquisar</label>
                        <input class="form-control form-control-sm" id="inputSearch" style="box-shadow: none; min-height:56px;" placeholder="Digite o que deseja esquisar" v-model="filters.filter_query">
                    </div>
                </div>
                <!-- <div class="col-md-3">
                    <div class="form-group">
                        <label for="inputUserName"></label>
                        <input type="email" class="form-control form-control-sm mt-2" id="inputUserName" style="box-shadow: none; min-height:56px;" :disabled="filters.filter_by === ''">
                    </div>
                </div> -->
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="selectCell">Célula</label>
                        <select class="form-control form-control-sm" id="selectCell" style="box-shadow: none; min-height:56px;" v-model="filters.cell_id">
                            <option value="">Todas</option>
                            <option v-for="cell in cells" :value="cell.id" :key="cell.id">{{ cell.cell }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="selectCell">Status</label>
                        <select class="form-control form-control-sm" id="selectCell" style="box-shadow: none; min-height:56px;" v-model="filters.status">
                            <option value="">Todos</option>
                            <option :value="true">Ativo</option>
                            <option :value="false">Inativo</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="col-md-2">
                    <div class="form-group">
                        <label for="inputUserSatus">Unidade</label>
                        <select class="form-control form-control-sm" id="inputUserSatus" style="box-shadow: none; min-height:56px;">
                            <option value="">Todas</option>
                            <option v-for="company in companies" :value="company.id" :key="company.id">{{ company.city }} / {{ company.state.acronym }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="inputUserSatus">Parceiro Comercial</label>
                        <select class="form-control form-control-sm" id="inputUserSatus" style="box-shadow: none; min-height:56px;">
                            <option value="">Todos</option>
                            <option v-for="commercialPartner in commercialPartners" :value="commercialPartner.id" :key="commercialPartner.id">{{ commercialPartner.trade_name }}</option>
                        </select>
                    </div>
                </div> -->
                <div class="col-md-3 d-flex justify-content-end">
                    <GlassButton
                    :loading="loading"
                    @click="applyFilters"
                    > 
                        <img src="@/assets/search.png" />
                        Pesquisar
                    </GlassButton>
                    <!-- <div class="form-group" style="max-width:260px;">
                        <button @click="applyFilters"
                            style="min-width: 100%; min-height: 56px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                        >
                            <span v-if="!loading">
                                <img src="@/assets/search.png" />
                                Pesquisar
                            </span>
                            <span v-else>
                                <b-spinner small />
                            </span>
                        </button>
                    </div> -->
                    <!-- <button class="btn btn-success ml-1" @click="$router.push({ name: 'advisorNew' })">Novo assessor</button> -->
                </div>
            </div>
        </template>

        <!-- CONTENT -->
        <b-table class="custom-table" borderless sticky-header :fields="columns" :items="advisors" show-empty empty-text="Não há dados disponíveis">
            <template #cell(name)="row">
                {{ row.item.first_name +' '+ row.item.last_name }}
            </template>
            <template #cell(hunterCount)="row">
                <div class="d-flex justify-content-center align-items-center">
                    <div style="display: flex; justify-content: center; align-itens: center;">
                        <b-button style="width: 45px; height: 40px; background-color:transparent !important; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color:#474646;" @click="showModalHunters(row.item.advisorCommercialPartner[0].hunters)">
                            <span class="px-2" style="background-color: #EB694A; color:black; font-weight:600">{{ row.item.advisorCommercialPartner.length > 0 ? row.item.advisorCommercialPartner[0].hunters.length : '' || 0 }}</span>
                        </b-button>
                    </div>
                </div>
            </template>
            <template #cell(advisorCommercialPartnerCount)="row">
                <div class="d-flex justify-content-center align-items-center">
                    <div style="display: flex; justify-content: center; align-itens: center;">
                        <b-button style="width: 45px; height: 40px; background-color:transparent !important; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color:#474646;" @click="showModalCommercialPartners(row.item.advisorCommercialPartner)">
                            <span class="px-2" style="background-color: #EB694A; color:black; font-weight:600">{{ row.item.advisorCommercialPartner.length }}</span>
                        </b-button>
                    </div>
                </div>
            </template>
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end align-items-center">
                    <b-form-checkbox switch size="sm" v-model="row.item.status" @change="activateOrInactivateAdvisor(row.item, row.item.status)"></b-form-checkbox>
                    <b-button 
                        size="sm"
                        @click="editAdvisor(row.item.id)" class="px-2"
                        style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                    >
                        <b-icon icon="pencil-fill"></b-icon>
                    </b-button>
                </div>
            </template>
        </b-table>

        <template #footer><div></div></template>

        </DefaultPageComponent>

        <card-modal title="Códigos de Parceiros comerciais" ref="modalCommercialPartners">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col mt-2">
                    <b-table striped borderless small sticky-header :fields="columnsCommercialPartner" :items="commercialPartnersInAdvisor" style="max-height: 60vh !important;" show-empty empty-text="Não há dados disponíveis"></b-table>
                </div>
            </div>
        </card-modal>
        <card-modal title="Finders" ref="modalHunter">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col mt-2">
                    <b-table striped borderless small sticky-header :fields="columnsHunters" :items="huntersInAdvisor" style="max-height: 60vh !important;" show-empty empty-text="Não há dados disponíveis"></b-table>
                </div>
            </div>
        </card-modal>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CardModal from './CardModal.vue';
import GlassButton from '@/components/GlassButton.vue';
// import DefaultHeader from '@/components/DefaultHeader.vue';

export default {
    name: 'AdvisorView',
    components: {
        // DefaultHeader,
        'card-modal': CardModal,
        DefaultPageComponent,
        GlassButton,
    },
    async mounted() {
        this.saveLog();
        await this.getCells();
        await this.getCompanies();
        await this.getCommercialPartners();
        await this.applyFilters();
    },
    data() {
        return {
            loading: false,
            filters: {
                filter_by: "name",
                filter_query: "",
                cell_id: "",
                status: true,
            },
            cells: [],
            companies: [],
            commercialPartners: [],
            advisors: [],
            columns: [
                {
                    'wflow_code': 
                    {
                        label: 'Código WFLOW',
                        class: 'text-left'
                    }
                },
                {
                    'name': 
                    {
                        label: 'Nome',
                        class: 'text-left'
                    }
                },
                {
                    'emails[0].email': 
                    {
                        label: 'E-mail',
                        class: 'text-left'
                    }
                },
                {
                    'cellHistories[0].cell.cell': 
                    {
                        label: 'Célula',
                        class: 'text-left'
                    }
                },
                {
                    'company.name': 
                    {
                        label: 'Unidade',
                        class: 'text-left'
                    }
                },
                {
                    'hunterCount': 
                    {
                        label: 'Finder',
                        class: 'text-center'
                    }
                },
                {
                    'advisorCommercialPartnerCount': 
                    {
                        label: 'Parceiro comercial',
                        class: 'text-center'
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            columnsCommercialPartner: [
                {
                    'code': 
                    {
                        label: 'Código',
                        class: 'text-right pb-2'
                    }
                },
                {
                    'trade_name': 
                    {
                        label: 'Parceiro comercial',
                        class: 'text-left pb-2'
                    }
                },
                {
                    'hunter_name': 
                    {
                        label: 'Finder',
                        class: 'text-left pb-2'
                    }
                },
                {
                    'farmer_name': 
                    {
                        label: 'Final',
                        class: 'text-left pb-2'
                    }
                },
                
                // {
                //     'commercialPartner.openedDate': 
                //     {
                //         label: 'Data de abertura',
                //         class: 'text-left'
                //     }
                // },
                // {
                //     'commercialPartner.activeDate': 
                //     {
                //         label: 'Data de ativação',
                //         class: 'text-left'
                //     }
                // },
            ],
            commercialPartnersInAdvisor: [],
            columnsHunters: [
                {
                    'code': 
                    {
                        label: 'Código',
                        class: 'text-right pb-2'
                    }
                },
                {
                    'advisor_name': 
                    {
                        label: 'Finder',
                        class: 'text-left pb-2'
                    }
                },
            ],
            huntersInAdvisor: [],
        }
    },
    methods: {
        saveLog () {
            let objLog = {
                description: 'Cadastro assessor'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        async applyFilters(){
            if(typeof this.filters.status == 'boolean') await this.getAdvisors(this.filters.status);
            else await this.getAdvisors();

            const temp = [...this.advisors];

            this.advisors = temp.filter(e=>{
                
                return (
                    (this.filters.filter_by=='wflow_code' ? e.wflow_code.includes(this.filters.filter_query) : true) 
                 && (this.filters.filter_by=='name' ? (e.first_name.toLowerCase()+' '+e.last_name.toLowerCase()).includes(this.filters.filter_query.toLowerCase()) : true) 
                 && (this.filters.cell_id ? e.cellHistories[0]?.cell?.id==this.filters.cell_id : true)
                );
            });
        },
        async getCells() {
            this.$axios.get('cells').then((response) => {
                this.cells = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar às células.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            });
        },
        async getCompanies() {
            this.$axios.get('companies').then((response) => {
                this.companies = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar às unidades.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            });
        },
        async getCommercialPartners() {
            this.$axios.get('commercial-partner').then((response) => {
                this.commercialPartners = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os parceiro comercial.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            });
        },
        async getAdvisors(status) {
            try{
                this.loading=true;
                var endpoint = 'advisors';
                if(typeof status == 'boolean') endpoint+='?status='+(status ? 1 : 0);
                const response = await this.$axios.get(endpoint);
                this.advisors = response.data.data;
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os assessores.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
            finally{ this.loading=false; }
        },
        showModalCommercialPartners(list) {
            if (list.length > 0) {
                var temp = [];
                list.forEach(item => {
                    temp.push(...item.hunter_relationship_farmer.map(subitem => {
                        return {
                            code: item.code,
                            trade_name: item.commercialPartner.trade_name,
                            hunter_name: subitem.hunter_name,
                            farmer_name: subitem.farmer_name,
                        }
                    }));
                });
                this.commercialPartnersInAdvisor = temp;
                this.$refs.modalCommercialPartners.openModal();
            }
            return;
        },
        showModalHunters(hunters) {
            if (hunters.length > 0) {
                this.huntersInAdvisor = hunters;
                this.$refs.modalHunter.openModal();
            }
            return;
        },
        editAdvisor(advisorID) {
            this.$router.push({ name: 'advisorEdit', params: { id: advisorID }});
        },
        activateOrInactivateAdvisor(advisor, status) {
            this.$swal({
                title: !status ? 'Inativar assessor?' : 'Ativar assessor?',
                text: !status ? `Deseja realmente inativar o assessor ${advisor.first_name + ' ' + advisor.last_name}?` : `Deseja realmente ativar o assessor ${advisor.first_name + ' ' + advisor.last_name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: !status ? 'Inativar' : 'Ativar',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            }).then((result) => {
                if (!result.isConfirmed) {
                    let url = !status ? `advisors/${advisor.id}/inactive` : `advisors/${advisor.id}/active`;

                    this.$axios.put(url).then((response) => {
                        if(response.status != 200) {
                            this.$swal({
                                title: 'Erro',
                                text: status ? 'Erro ao ativar o assessor.' : 'Erro ao inativar o assessor.',
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#C44C30',
                                allowOutsideClick: false,
                            });
                            return;
                        }
                        this.$swal({
                            title: 'Sucesso',
                            text: status ? 'Assessor ativado com sucesso.' : 'Assessor inativado com sucesso.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#C44C30',
                            allowOutsideClick: false,
                        });
                        this.getAdvisors();
                    });
                } else {
                    this.getAdvisors();
                }
            });
        },
    }
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #28A745 !important;
    background-color: #28A745 !important;
    box-shadow: none !important;
}

.custom-control-input:not(checked) ~ .custom-control-label::before {
    border-color: #CCC !important;
    box-shadow: none !important;
}

tr:nth-child(odd) {
    background-color: #DDD !important;
}
</style>
