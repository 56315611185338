<template>
    <div style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Top funds - Data referência dos fundos - '+ $options.filters.formatDate(modal.reference_date)"
            :haveBackButton="true"
            @onBack="$router.push({name: 'topFunds'})"
            >

            <!-- CONTENT -->
            <b-col class="p-0" style="min-height: 100%">
                <div style="font-size:18px" class="py-2">
                    {{ 'Data atualização - ' + $options.filters.formatDate(modal.updated_at) }}
                </div>

                <b-row class="m-0">
                    <b-col cols="3" class="p-0" style="position:relative">
                        <b-form-input
                            id="inputCNPJTopFunds"
                            name="inputCNPJTopFunds"
                            v-model="n_document"
                            placeholder="Digite o CNPJ"
                            v-mask="'##.###.###/####-##'"
                            style="min-height:56px;"
                            required
                        ></b-form-input>
                        <b-button @click="search" variant="link" style="position:absolute; top:8px; right:10px; color: white;">
                            <b-icon icon="search"></b-icon>
                        </b-button>
                    </b-col>
                    <b-col class="ml-3 d-flex align-items-center" :style="'background-color:#'+ (item ? item?.color_code : 535252) +'; font-size:20px; color:'+$options.filters.textColorBlackOrWhite('#'+item?.color_code)">
                        {{ item ? item.description : hasSearch ? 'Nenhuma classificação encontrada.' : ''}}
                    </b-col>
                    <b-col cols="3">
                        <CustomButton
                        @click="addFund"
                        :disabled="!isValid"
                        bgColor="#28A745"
                        textColor="#28A745"
                        borderColor="#28A745"
                        fontSize="20px"
                        width="100%"
                        > ADICIONAR FUNDO
                        </CustomButton>
                    </b-col>
                </b-row>

                <b-col class="py-3">
                    <TopFundsTable :items="item ? [{funds: [item.funds]}] : [{funds:[]}]"/> 
                </b-col>

                <b-col class="py-3" style="background-color:#262625; border-radius:12px;">
                    <TopFundsTable height="calc(100vh - 550px)" :key="items.length" :items="items" canDelete @delete="removeFund"/>
                </b-col>
            </b-col>

            <template #footer>
                <b-row align-h="between" align-v="center" class="m-0">
                    <CustomButton
                    @click="$router.push({name: 'topFunds'})"
                    disabled
                    > Voltar
                    </CustomButton>

                    <CustomButton
                    @click="save"
                    :loading="loadingSubmit"
                    :disabled="!isValidSave"
                    > Salvar
                    </CustomButton>
                </b-row>
            </template>

            </DefaultPageComponent>
        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import TopFundsTable from '@/components/TopFundsTable.vue';
import CustomButton from '@/components/CustomButton.vue';
import { mapState } from 'vuex';

export default {
    name: "TopFundsFormView",
    components:{
        DefaultPageComponent,
        TopFundsTable,
        CustomButton,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,

            hasSearch: false,
            hasEdited: false,
            n_document: null,

            modal: {},
            item: null,
            items: [],
        }
    },
    created(){
        if(this.user.role!=1){
            this.$router.push({name: 'topFunds'})
        }
        this.getItems();
    },
    computed:{
        ...mapState('user', {
            user: (state) => state.user,
        }),
        isValid(){
            return Boolean(
                this.item
                && !this.items.some(e=>e.funds.find(f=>f.n_document==this.item?.funds?.n_document))
            )
        },
        isValidSave(){
            return Boolean(
                this.hasEdited
            )
        },
    },
    methods: {
        async search(){
            try{
                this.loading=true;
                var endpoint = 'top-funds/'+this.n_document.replace(/\D/g,'');
                const response = await this.$axios.get(endpoint);
                if(response.data.message)
                    this.item=null;
                else this.item = response.data;
                this.hasSearch=true;

                this.item.funds.n_document = this.n_document.replace(/\D/g,'')
            }
            catch(error){
                this.item = null;
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar o fundo.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getItems(){
            try{
                this.loading=true;
                var endpoint = 'top-funds';
                const response = await this.$axios.get(endpoint);
                this.modal = response.data;
                this.items = response.data.classifications;
                this.items.sort((a,b)=>a.position-b.position)
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar a listagem de Top Funds.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            }
            finally{ this.loading=false; }
        },
        removeFund(item){
            this.$swal({
                title: 'Excluir registro?',
                text: `Tem certeza que deseja excluir o fundo ${this.items[item.row_idx].funds[item.item_idx].fund} da listagem de Top Funds?`,
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                denyButtonText: 'Excluir',
                denyButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    denyButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    this.items[item.row_idx].funds.splice(item.item_idx,1);
                    this.hasEdited=true;
                }
            });
        },
        addFund(){
            if(this.items.some(e=>e.funds.find(f=>f.n_document==this.item?.funds?.n_document))){
                this.$swal({
                    title: 'Fundo já adicionado',
                    text: 'O fundo selecionado já está presente na listagem de Top Funds',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            else if(!this.isValid){
                this.$swal({
                    title: 'Atenção',
                    text: 'Pesquise o fundo antes de adicionar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }

            this.hasEdited=true;

            var row_idx = this.items.findIndex(e=>e.id==this.item.id);
            if(row_idx >= 0){
                this.items[row_idx].funds.push(this.item.funds);
            }
            else{
                const tempFunds = [this.item.funds]
                this.item.funds = tempFunds;
                this.items.push(this.item);
            }

            this.items.sort((a,b)=>a.position-b.position)

            this.n_document = null;
            this.item = null;
        },
        async save(){
            if(!this.isValidSave){
                this.$swal({
                    title: 'Atenção',
                    text: 'Adicione um fundo antes de salvar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }

            try{
                this.loading=true;

                var endpoint = 'top-funds';
                await this.$axios.post(endpoint, {
                    funds: this.items.map(row=>{
                        return {
                            "top_fund_classification_id": row.id,
                            "n_documents": row.funds.map(e=>e.n_document)
                        }
                    })
                });
                
                this.$swal({
                    title: 'Sucesso',
                    text: 'A listagem de Top Funds foi salva com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28a745',
                    allowOutsideClick: false,
                })
                .then(()=>{
                    this.$router.push({name: 'topFunds'})
                })
            }
            catch(error){
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível salvar a listagem de Top Funds.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                console.log(error);
            }
            finally{ this.loading=false; }
        },
    },
}
</script>

<style scoped>

</style>