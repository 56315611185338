<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="item.id ? 'Visualização e edição de E-mail padrão' : 'Cadastro de E-mail padrão'"
            :haveBackButton="true"
            @onBack="$router.push({name: 'defaultEmailsView'})"
            >

            <template #filters>
                <b-row class="mx-0">
                    <b-col class="px-1 pr-2" cols="4">
                        <div class="form-group">
                            <label for="inputClassificationEmail">Classificação</label>
                            <select @change="verifyNew" class="form-control form-control-sm" id="inputClassificationEmail" style="box-shadow: none; height: 56px;"
                            v-model="classification" :disabled="!isEdit && item.id">
                                <option :value="null" disabled> {{ 'Selecione a classificação' }} </option>
                                <option :value="''"> {{ 'Nova classificação' }} </option>
                                <option v-for="item, idx in classifications" :key="idx" :value="item.id"> {{ item.title }} </option>
                                <!-- <option :value="'Informativo'"> {{ 'Informativo' }} </option>
                                <option :value="'Aniversário'"> {{ 'Aniversário' }} </option> -->
                            </select>
                        </div>
                    </b-col>
                    <b-col class="pl-2 px-1" cols="8">
                        <div class="form-group" :style="!classification ? 'opacity:0.5;' :''">
                            <label for="inputSubject">Assunto</label>
                            <input type="text" class="form-control form-control-sm" id="inputSubject" placeholder="Informe um assunto para o e-mail" style="box-shadow: none; height: 56px;"
                                v-model="subject" :disabled="!classification || !isEdit">
                        </div>
                        <div v-if="!classification && isEdit" @click="alertSubject" class="h-100 w-100" style="position:absolute; z-index:9; top:0;"></div>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-col @click="alertBody" class="p-0" style="min-height:100%;" :style="!classification || !subject ? 'opacity:0.5;' :''">
                <b-row class="mx-0 py-4">
                    <b-checkbox v-model="advisorIdentity" :disabled="!classification || !subject || !isEdit" size="lg"> Identidade do assessor </b-checkbox>
                </b-row>
                
                <div style="position: relative;">
                    
                    <div v-if="advisorIdentity" class="w-100 d-flex align-items-center justify-content-center"
                    style="position: absolute; top:65px;">
                        <img class="img-fluid" src="@/assets/header.png" alt="Imagem da identidade do assessor no cabeçalho"
                        style="max-height:100px;">
                    </div>
                    
                    <vue-editor v-model="message" :disabled="!classification || !subject || !isEdit"
                    ref="editor"
                    :editor-toolbar="customToolbar"
                    :editorOptions="editorSettings"
                    :customModules="customModulesForEditor"
                    :theme="'Bubble'"
                    :class="advisorIdentity ? 'advisor-identity' : ''"
                    style="background-color:white; color: black;
                    height:100% !important"/>
                    
                    <div v-if="advisorIdentity" class="w-100 d-flex align-items-center justify-content-center"
                    style="position: absolute; bottom:15px;">
                        <img class="img-fluid" src="@/assets/footer.png" alt="Imagem da identidade do assessor no rodapé"
                        style="max-height:100px;">
                    </div>

                </div>

                <div v-if="!classification || !subject || !isEdit" class="w-100" style="height:130px; position: absolute; z-index:2 !important; top:0;"></div>
            </b-col>

            <template #footer>
                <b-row v-if="isEdit" class="mx-0" align-h="between">
                    <CustomButton disabled
                    @click="$router.push({name: 'defaultEmailsView'})"
                    > Cancelar
                    </CustomButton>

                    <CustomButton 
                    :disabled="!isValid"
                    @click="save"
                    > Salvar
                    </CustomButton>
                </b-row>
                <b-row v-else class="mx-0" align-h="end">
                    <CustomButton
                    @click="isEdit=true"
                    > Editar
                    </CustomButton>
                </b-row>
            </template>

            </DefaultPageComponent>

            <b-modal id="newClass" title="Nova classificação" centered hide-footer>
                <b-col class="my-3">
                    <b-row>
                        <b-col>
                            <div class="form-group">
                                <label for="inputNewClassificationTitle">Título</label>
                                <input type="text" class="form-control form-control-sm" id="inputNewClassificationTitle" style="box-shadow: none; height: 56px;"
                                placeholder="Título" v-model="newClass.title">
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="my-4">
                        <b-col>
                            <div class="form-group">
                                <label for="inputNewClassificationDescription">Descrição</label>
                                <input type="text" class="form-control form-control-sm" id="inputNewClassificationDescription" style="box-shadow: none; height: 56px;"
                                placeholder="Descrição" v-model="newClass.description">
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="m-0 mb-2" align-h="end" align-v="center">
                        <CancelButton
                        textColor="grey"
                        @click="$bvModal.hide('newClass')"
                        > Cancelar
                        </CancelButton>

                        <CustomButton
                        @click="createClassification"
                        :disabled="!newClass.title"
                        :loading="loadingCreate"
                        > Salvar
                        </CustomButton>
                    </b-row>
                </b-col>
            </b-modal>
        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import { VueEditor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import CancelButton from '@/components/CancelButton.vue';

export default {
    name: "EmailsNewView",
    components:{
        DefaultPageComponent,
        CustomButton,
        VueEditor,
        CancelButton,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,
            loadingCreate: false,

            newClass: {
                title: '',
                description: '',
            },

            classifications: [],
            classification: null,
            subject: null,
            message: null,
            advisorIdentity: null,

            isEdit: true,

            item: {},
            customToolbar: [
                [{'font':[]}, {'header':[]}],
                [
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                ],
                [
                    {'align': []},
                ],
                [
                    {'indent': '-1'},
                    {'indent': '+1'},
                    {'list': 'ordered'},
                    {'list': 'bullet'},
                ],
                [
                    {'color': []},
                    {'background': []},
                ],
                [
                    'blockquote',
                    'code-block',
                    'code',
                ],
                [
                    'link',
                    'image',
                ],
            ],
            customModulesForEditor: [
                { alias: "imageDrop", module: ImageDrop },
                { alias: "imageResize", module: ImageResize }
            ],
            editorSettings: {
                modules: {
                    imageDrop: true,
                    imageResize: {
                        displaySize: true
                    },
                },
            }
        }
    },
    async created(){
        await this.getClassifications();

        console.log(this.$refs.editor.quill)

        if(this.$route.params.item){
            this.isEdit=false;
            this.item=this.$route.params.item;
            this.item.id=this.item.template.id;

            this.classification = this.classifications.find(e=>e.title==this.item.classification_title)?.id
            
            this.subject = this.item.template.subject;
            this.message = this.item.template.body;
            this.advisorIdentity = this.item.template.include_advisor_identity==1;
        }
    },
    computed:{
        isValid(){
            return Boolean(
                this.classification
                && this.subject
                && this.message
                && this.validateDifference
            );
        },
        validateDifference(){
            return this.item.id
                ?   (this.item.template?.body!=this.message
                    || this.item.template?.subject!=this.subject
                    || this.item.classification_title!=this.classifications.find(e=>e.id==this.classification)?.title
                    || (!!this.item.template?.include_advisor_identity) != this.advisorIdentity)
                : true
        },
    },
    methods: {
        async getClassifications(){
            try{
                this.loading=true;
                var endpoint = 'email-classification';
                const response = await this.$axios.get(endpoint);
                this.classifications = response.data.data;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        verifyNew(){
            if(this.classification==''){
                this.newClass = {
                    title: '',
                    description: '',
                };
                this.$bvModal.show('newClass');
            }
        },
        alertSubject(){
            if(!this.classification){
                this.$swal({
                    title: 'Atenção',
                    text: 'Escolha uma classificação para habilitar o campo de assunto.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        alertBody(){
            if(!this.classification && !this.subject){
                this.$swal({
                    title: 'Atenção',
                    text: 'Escolha uma classificação e preencha o assunto antes de habilitar este campo.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
            else if(!this.subject){
                this.$swal({
                    title: 'Atenção',
                    text: 'Preencha o assunto antes de habilitar este campo.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        validate(){
            if(!this.validateDifference){
                this.$swal({
                    title: 'Atenção',
                    text: 'Não houve alterações no formulário para salvar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return false;
            }
            else if(!this.isValid){
                this.$swal({
                    title: 'Atenção',
                    text: 'Preencha o formulário antes de salvar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return false;
            }
            else return true;
        },
        createClassification(){
            if(!this.newClass.title){
                this.$swal({
                    title: 'Atenção',
                    text: 'Existe(m) campo(s) obrigatório(s) ainda não preenchido(s)!',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            }

            this.loadingCreate=true;
            this.$axios.post('email-classification', this.newClass)
            .then(async (response) => {
                
                this.$bvModal.hide('newClass')
                
                this.$swal({
                    title: 'Registro cadastrado',
                    text: 'Registro cadastrado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });

                await this.getClassifications();

                this.classification = response.data.data.id

            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível cadastrar a classificação.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }).finally(() => {
                this.loadingCreate = false;
            });

        },
        questionSave(){
            if(!this.validate()) return;
            
            this.$swal({
                title: 'Salvar e-mail padrão?',
                text: `Deseja realmente salvar o e-mail padrão ${this.subject}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: 'Salvar',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            }).then((result) => {
                if (!result.isConfirmed) {
                    this.save();
                }
            })
        },
        async save(){
            if(!this.validate()) return;

            var temp = {
                subject: this.subject,
                body: this.message,
                department_type: 'Geral',
                email_classification_id: this.classification,
                status: 1,
                include_advisor_identity:  this.advisorIdentity ? 1 : 0,
            }


            if(this.item.id)
                await this.$axios.put('email-template/'+this.item.id, temp);
            else
                await this.$axios.post('email-template', temp);

            this.$swal({
                title: 'Sucesso',
                text: this.item.id ? 'Alterações no e-mail padrão salvas com sucesso.' : 'E-mail padrão salvo com sucesso.',
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#28A745',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                }
            }).then(()=>{
                this.$router.push({name: 'defaultEmailsView'})
            });

        },
        // extractBase64FromHtml(htmlText) {
        //     const result = [];
        //     const regex = /<img[^>]*\s+src=\\?"data:image\/[^;]+;base64,([^\\"]+)\\?"[^>]*>/gi;
        //     let match;
        //     let index = 1;

        //     while ((match = regex.exec(htmlText)) !== null) {
        //         result.push({
        //         imageBase64: match[1],
        //         display_order: index,
        //         });
        //         index++;
        //     }

        //     return result;
        // }
    },
}
</script>

<style scoped>
* /deep/ .modal-content {
    border-radius: 10px !important;
}
* /deep/ .modal-header {
    border: none !important;
}
* /deep/ .modal-header .close {
    color: white;
}
* /deep/ .modal-body {
    background-color: #2B2B2B !important;
}

* /deep/ .advisor-identity .ql-editor {
    padding: 120px 15px;
    min-height:300px;
}

* /deep/ .ql-font-serif{
    font-family: Georgia, Times New Roman, serif !important;
}
* /deep/ .ql-font-monospace{
    font-family: Monaco, Courier New, monospace !important;
}
</style>