import { render, staticRenderFns } from "./TopFundsTable.vue?vue&type=template&id=07007ebc&scoped=true"
import script from "./TopFundsTable.vue?vue&type=script&lang=js"
export * from "./TopFundsTable.vue?vue&type=script&lang=js"
import style0 from "./TopFundsTable.vue?vue&type=style&index=0&id=07007ebc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07007ebc",
  null
  
)

export default component.exports